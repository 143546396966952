import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import HeaderSection from '../components/section-header'
import BlogListSection from '../components/section-blog-list'
import CaseStudyListSection from '../components/section-case-study-list'

export default ({ pageContext, data }) => {
  const tag = pageContext.tag
  const caseStudies = data.caseStudies.edges
  const blogPosts = data.blogPosts.edges

  return (
    <Layout>
      <HeaderSection
        small
        eyebrow={`Content tagged with:`}
        title={tag}
      />
      <CaseStudyListSection posts={caseStudies} />
      <BlogListSection title="Related Blog Posts" posts={blogPosts} />
    </Layout>
  )
}

export const query = graphql`
  query TagQuery($tag: String) {
    caseStudies: allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "case-study"}, published: {eq: true}, tags: {eq: $tag}}}) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            description
            tags
            teaserImage {
              childImageSharp {
                fixed(height: 225, width: 599, fit: COVER) {
                  src
                }
              }
            }
          }
        }
      }
    }
    blogPosts: allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "blog-post"}, published: {eq: true}, tags: {eq: $tag}}}, sort: {fields: frontmatter___date, order: DESC}) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            description
            tags
            # teaserImage {
            #   childImageSharp {
            #     fixed(height: 225, width: 599, fit: COVER) {
            #       src
            #     }
            #   }
            # }
          }
        }
      }
    }
  }
`