import React, { useState } from 'react'
import { Link } from "gatsby"
import styled from '@emotion/styled'
import Card from '@material-ui/core/Card';
import cardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
import Tags from './tag-list'

const CardLink = styled(Link)`
  :hover {
    text-decoration: none;
  }
`

const CardMedia = styled(cardMedia)`
  height: 225px;
`

export default ({ post }) => {
  const [hover, setHover] = useState(false)
  const onMouseOver = () => setHover(true)
  const onMouseOut = () => setHover(false)

  return (
    <CardActionArea component={CardLink} to={post.fields.slug}>
      <Card raised={hover} onMouseOver={onMouseOver} onMouseOut={onMouseOut} onFocus={onMouseOver} onBlur={onMouseOut}>
        {post.frontmatter.teaserImage &&
          <CardMedia
            image={post.frontmatter.teaserImage.childImageSharp.fixed.src}
            title={post.frontmatter.title}
          />
        }
        <CardContent>
          <Tags tags={post.frontmatter.tags} />
          <Typography component="h5" variant="h5">{post.frontmatter.title}</Typography>
          <Typography>{post.frontmatter.description}</Typography>
        </CardContent>
      </Card>
    </CardActionArea>
  )
}