import React from 'react'
import Grid from '@material-ui/core/Grid';
import Section from './section'
import CaseStudyCard from './case-study-card'

export default ({ posts }) => (
  <Section>
    <Grid container spacing={3}>
      {posts && posts.map(({ node: post }) => (
        <Grid key={post.id} item xs={12} sm={6} md={4}>
          <CaseStudyCard post={post} />
        </Grid>
      ))}
    </Grid>
  </Section>
)

